.catalogo-cards-container-width {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.catalogo-cards-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  padding: 15px 0;
}

@media screen and (max-width: 1900px) {
  .catalogo-cards-container {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1600px) {
  .catalogo-cards-container {
    max-width: 880px;
  }
}

@media screen and (max-width: 850px) {
  .catalogo-cards-container {
    justify-content: center;
  }
}

@media screen and (max-width: 1200px) and (min-width: 850px) {
  .catalogo-cards-container {
    max-width: 560px;
  }
}
