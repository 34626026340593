.icono-home-sec-rec{
    position: absolute;
    opacity: .1;
    z-index: 20;
}


.icono-home-sec-rec-1{
    width: 80px;
    top: 100px;
    left: 280px;
}

.icono-home-sec-rec-2{
    width: 110px;
    bottom: 130px;
    left: 170px;
    transform: rotate(-45deg);
    opacity: .1;
}

.icono-home-sec-rec-3{
    width: 50px;
    top: 150px;
    right: 300px;
}

.icono-home-sec-rec-4{
    width: 90px;
    bottom: 150px;
    right: 250px;
    transform: rotate(45deg);
}