.modal {
  z-index: 1000;
}
.modalBox {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 500px;
  width: 600px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 24;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.closeBtnContainer {
  width: 100%;
  display: flex;
  justify-content: end;
}

.modalBox .inputContainer {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.modalBox input {
  height: 30px;
  border: none;
  padding-left: 10px;
  border-bottom: 2px solid rgb(191, 191, 191);
}
.modalBox input:focus {
  outline: none;
  background-color: rgb(228, 228, 228);
}

.modalBox textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 50px;
  border: 1px solid rgb(197, 197, 197);
  padding-left: 10px;
}
.modalBox textarea:focus {
  outline: none;
  background-color: rgb(228, 228, 228);
}

@media screen and (max-width: 600px) {
  .modalBox {
    width: 90%;
  }
}
