
.carrito-vacio{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

/* .section-carrito-vacio-texto{
    display: flex;
    justify-content: center;
    align-items: center;
}
.section-carrito-vacio-texto{} */