body {
  font-family: 'Poppins', sans-serif;
}

:root {
  --full-height: calc(100vh - 230px);
  --color-light-gray: rgba(151, 151, 151, 1);
}

a {
  text-decoration: none;
  color: white;
}

.swal2-container {
  z-index: 100000;
}

.container-section {
  padding: 30px 20px;
}

@media print {
  #root {
    display: none;
  }
  #modal {
    width: 100%;
    height: 100vh;
    border: 0 solid transparent;
    border-radius: 0;
    box-shadow: none;
  }
  button {
    display: none !important;
  }
}
