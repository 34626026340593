.Educacion-financiera-videos-section{
    width: 100%;

}

.Educacion-financiera-videos{
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.edu-videos-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.piggy-bank-img{
    max-width: min(55%,1000px);
    border-radius: 20px;
    box-shadow: 6px 4px 18px -6px rgba(0,0,0,0.75);
}


@media screen and (max-width: 600px) {
    .Educacion-financiera-videos{
        flex-direction: column-reverse;
        justify-content: start;
        align-items: start;
        gap: 30px;
    }
    .piggy-bank-img{
        max-width: min(100%,1000px);
    }
    
}


@media screen and (max-width: 400px){
    .Educacion-financiera-videos{
        justify-content: center;
        align-items: center;
    }
}