.column-btn-carrito-table{
   gap: 10px;
}

.row-carrito h6{
   font-size: 19px;
}

@media screen and (max-width: 900px) {
   .row-carrito h6{
      font-size: 17px;
   }  
   
}
@media screen and (max-width: 600px) {
   .row-carrito h6{
      font-size: 15px;
   }  
   
}
@media screen and (max-width: 500px) {
   .row-carrito h6{
      font-size: 14px;
   }  
   
}