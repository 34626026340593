.PopCategoriesSection{
    width: 100%;
    padding: 20px;
    display: flex;
    padding: 50px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.img-cat-popu{
    width: 100%;
}