.Loading-section{
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 8000;
    background-color: rgba(239, 239, 239, 0.722);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}