.flex-container-carrito-total{
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.select-carrito{
    max-width: 600px;
    width: 100%;
    height: 40px;
    background-color: rgb(219, 219, 219);    
}
