.mis-datos-form{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.select-avatar{
    max-height: 50px;    
    display: flex;
    gap: 10px;
    align-items: center;

}
.select-avatar div{
    display: flex;
    gap: 10px;
    align-items: center;
}

.select-avatar img{
    width: 40px;;
}

.menu-item-avatar{
    display: flex;
    gap: 10px;
    align-items: center;
}

.menu-item-avatar img{
    width: 30px;
}