/* Footer.css */

.footer-container {
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .footer-links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: white;
    list-style: none;
    font-size: 13px;
  }
  
  .footer-links li:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  
  .logo-footer {
    cursor: pointer;
    width: 125px;
  }
  