.input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
  padding: 10px;
}
.input-container label {
  font-size: 16px;
}

.input-form {
  padding: 0 1.2rem;
  height: 40px;
  border-width: 1px;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.3);
  color: #000000;
  border-style: solid;
}
.input-form--required {
  color: rgb(255, 68, 68);
}
.input-form--error {
  border: 1px solid rgb(255, 68, 68);
}
.input-form:focus {
  outline: none;
}

.read-only-style {
  background-color: rgb(230, 230, 230);
}

/* 2do estilo*/

.input-container-dos {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
  padding: 10px;
}
.input-container label {
  font-size: 16px;
}

.input-form-dos {
  padding: 8px 16px 0px 16px;
  border-width: 0px;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.3);
  color: #000000;
  border-style: solid;
}
.input-form--required {
  color: rgb(255, 68, 68);
}
.input-form--error {
  border: 1px solid rgb(255, 68, 68);
}
.input-form:focus {
  outline: none;
}

.read-only-style {
  background-color: rgb(230, 230, 230);
}
