.catalogo-section {
    display: flex;
    width: 100%;
    gap: 20px;
}

.gift-card-section {
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
}

.catalogo-productos {
    width: 100%;
}

.select-catalogo-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.select-catalogo-container p {
    display: flex;
    align-items: center;
}

.select-catalogo-container div {
    display: flex;
    gap: 10px;
}

.pagination-container {
    margin-top: auto;
    margin-left: 150px;
}

@media screen and (max-width: 747px) {
    .catalogo-section {
        flex-direction: column;
    }

    .select-catalogo-container p {
        display: none;
    }

}