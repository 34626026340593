.error-page-section{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    min-height: var(--full-height);
}

.error-page-explamation{
    position: absolute;
    top: -26px;
    right: -35px;
}