.card-video-container{
    display: flex;
    gap: 20px;
    /* justify-content: center; */
    align-items: center;
    position: relative;
    
}

.card-video{
    width: 200px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
    border-radius: 10px;
    cursor: pointer;
    z-index: 100;
}

.card-video-active{
    transform: scale(1.1);
}

.box-nivel-video{
    color: white;
    width: 200px;
    height: 72px;
    border-radius: 10px;
    font-size: 18px;
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 200;

}

.box-nivel-video--active{
    display: flex;
    right: -230px;
    /* right: 0px; */

}

.div-todo-pantalla-video{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 20;
}
@media screen and (max-width: 400px){
    .card-video-container{
        flex-direction: column;

    }
    .box-nivel-video{
        position: relative;
        right: 0;
    }
}