.grid-gradient{
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-input-container{
    margin: 15px 0;
    display: flex;
    flex-direction: column;
}

.logo-login-desktop{
    width: 250px;
    cursor: pointer;
    
}


.logo-login-desktop:hover{
    transform: scale(1.08);
}