.SectionImage{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    flex-grow: 1;
    gap: 70px;
    z-index: 2;

    @media (max-width:768px) {
        & {
           gap: 20px;
        }
    }

    @media (max-width:480px) {
        & {
           gap: 0;
        }
    }
}


.icono-home-sec-fin{
    position: absolute;
    opacity: .3;
    z-index: 20;
}
.icono-home-sec-fin-1{
    bottom: 100px;
    left: 120px;
    width: 75px;
    transform: rotate(45deg);
}
.icono-home-sec-fin-2{
    top: 70px;
    left: 160px;
    width: 60px;
    
}
.icono-home-sec-fin-3{
    top: 120px;
    right: 100px;
    width: 85px;
    
}
.icono-home-sec-fin-4{
    bottom: 50px;
    right: 50px;
    width: 65px;
    transform: rotate(-45deg);
    
}

.SectionImage .edu-financiera-img{
    border-radius: 20px;
    width: 250px;
    z-index: 50;
    box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
    @media (max-width:768px) {       
        &{
            width: 200px;
        } 
     }

    @media (max-width:480px) {   
     &{
         width: 150px;

     }   
 }

}

.SectionImage .front {
    width: 300px;
    position: absolute;
    box-shadow: rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px;
        

    @media (max-width:768px) {       
           &{
               width: 250px;
           } 
        }

    @media (max-width:480px) {   
        &{
            width: 180px;

        }   
    }

}

