.user-stats-section{
    padding-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-stats-container{
    cursor: pointer;
    max-width: 1000px;
    width: 100%;
    min-height: 160px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-stat{
    width: 100%;
    max-width: "33%";
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px;
}

.user-stat-text{
    display: flex;
    flex-direction: column;
}
