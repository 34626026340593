.type-of-recognition-card-container {
  margin-top: 30px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.type-of-recognition-card {
  width: 350px;
  border-radius: 15px;
  height: 200px;
  background-color: #005984;
  border: 2px solid #0d8dce;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

li.type-of-recognition-card:hover {
  background-color: #0b9ee7;
  border: 2px solid #0b9ee7;
}

.type-of-recognition-card img {
  width: 350px;
  height: 200px;
  object-fit: cover;
  opacity: 0.4;
}

.recognition-card-text {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 20px;
  color: white;
  padding: 5px 10px;
  top: 20%;
}

.recognition-card-title {
  font-size: xx-large;
  text-align: center;
  font-weight: 900;
}

.type-of-recognition-card--active {
  transition: instant;
  background-color: #0499e4;
}
