.recognition-comment {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: 200px;
  max-height: 350px;
  min-height: 200px;
  padding-top: 10px;
  transition: none;
}

.recognition-comment:focus {
  outline: none;
}
