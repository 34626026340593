.educacion-financiera-section{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.nivel-actual-card-section{
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.edu-fin-nivel-actual-card{
    max-width: 1100px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;  
    min-height: 150px;
    border-radius: 10px;  
    display: flex;
    gap: 30px;
    align-items: center;
}

.edu-fin-nivel-actual-card-text{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.disk-progress{
    width: 125px;
    height: 125px;
    border-radius: 50%;
    border: 20px solid #69e363; 
    display: flex;
    justify-content: center;
    align-items: center;
}