.input-container-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
    
}
.input-date{
    padding: 0.4rem 1.2rem;
    border-width: 1px;
    border-radius: 5px;
    border-color: rgba(0, 0, 0, 0.3);
    color: #000000;
    height: 40px;
    border-style: solid;
}