.radio-container{
    display: flex;
    gap: 5px;
    align-items: center;
}
.login-input-container{
    margin-bottom: 20px;
}

.login-elipse-top{
    border-radius: 50%;
    background-color: #6ff1c3;
    width: 409px;
    height: 409px;
    position: fixed;
    top: -220px;
    right: -280px;
}

.login-elipse-bottom{
    border-radius: 50%;
    background-color: #80a3e3;
    opacity: 0.25808900594711304;
    width: 50%;
    height: 100%;
    position: fixed;
    bottom: -85%;
    right: 0;
    transition: all 0.5s ease-out
}

@media screen and (max-width: 900px) {
    .login-elipse-bottom{
        width: 100%;
    }
    
}