.mis-datos-section-form{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
}

.mis-datos-container-form{
    max-width: 890px;
    width: 95%;
    border-radius: 10px;
}