.filtro-categoria-container{
    min-height: 130px;
    max-height: 230px;
    overflow-y: auto;
}
.filtro-categoria-container::-webkit-scrollbar{
    width: 5px;
    height: 5px;

}

.filtro-categoria-container::-webkit-scrollbar-track{
    background-color: rgb(151, 151, 151);
    border-radius: 50px;
   
}

.filtro-categoria-container::-webkit-scrollbar-thumb{
    background-color: rgb(35, 42, 42);
    width: 3px;
    border-radius: 50px;
}


.filtro-categoria-container:hover {
    overflow: auto;

}