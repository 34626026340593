.select-catalogo{
    width: 180px;
    height: 40px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    color: var(--color-light-gray);
    font-family: Poppins, sans-serif;
    border: none;
    font-size: 18px;
}

.select-catalogo:focus .option-select-label-catalogo {
    position: relative;
    pointer-events: all;

} 
.options-container-catalogo{
    position: absolute;
    background-color: white;
    z-index: 1000;
    display: flex;
    top: 40px;
    transition: none;
    flex-direction: column;
    width: 100%;
    box-shadow: 10px 10px 10px rgb(235, 235, 235)
}

.option-select-label-catalogo{
    padding: 0 30px 0 10px; 
    
    display: flex;
    align-items: center;
    border-top: 1px solid rgb(232, 232, 232);
    width: 100%;
    overflow: hidden;
}

.option-select-label-catalogo:hover{
    background: rgb(233, 233, 233);


}

.option-select-input-catalogo{
    opacity: 0;
    display: hidden;
    position: absolute;
    left: 0;
}