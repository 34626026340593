.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  main {
    flex-grow: 1;  
    margin-top: 80px; 
    width: 100%;
  }
  