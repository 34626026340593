/*!  Swal no reconoce estilos .ts */
.popup {
  display: flex;
  flex-direction: column;
  position: left;
  justify-content: start;
  align-items: start;
  text-align: left;
}

.popup .swal2-html-container {
  text-align: left;
  font-size: 15px;
}

.title {
  display: flex;
  justify-content: start;
  text-align: left;
  font-size: 20px;
  color: #1d7fb8;
}

.popup .cancelBtn {
  background-color: #1d7fb8;
  border-radius: 30px;
}
