.filtro-container {
  width: 100%;
  margin-top: 5px;
  max-width: 280px;
  font-size: 13px;
}

.filtros-catalogo-header {
  display: flex;
  gap: 10px;
  margin-bottom: 22px;
  justify-content: space-around;
  align-items: center;
}
.filtro-title {
  color: var(--color-light-gray);
  font-weight: 500;
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 5px;
}

@media screen and(max-width: 800px) {
  .filtro-container {
    max-width: 100%;
  }
}
