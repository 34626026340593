/* //! _no se puede aplicar estilos a atributos html con archivos .ts */

#logo {
  width: 130px;
}

@media screen and (max-width: 900px) {
  #logo {
    width: 100px;
  }
}
