.catalogo-card {
  transition: 0.3s;
  cursor: pointer;
  overflow: hidden;
  width: 270px;
  height: 310px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  align-self: center;
  justify-self: center;
  position: relative;
  border-radius: 10px;
}
.catalogo-card:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 6px 24px;
}

.catalogo-card-img-container {
  display: flex;
  width: 100%;
  height: 150px;
  position: relative;
}
.catalogo-card img {
  width: 100%;
  transform: scale(1.05);
  height: 150px;
}

.catalogo-card-info {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
}

.catalogo-bottom-card-container {
  position: absolute;
  bottom: 3px;
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
}
